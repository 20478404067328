import React from "react";

import { Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    width: "12.063rem",
    alignItems: "center",
    justifyContent: "center",
    border: "0.063rem solid #e0e0e0",
    borderRadius: "0.313rem",
    padding: "0.625rem",
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "0.625rem",
  },
  cardTitle: {
    fontSize: "0.813rem",
    color: "#757575",
  },
  cardSubtitle: {
    fontSize: "0.625rem",
    color: "#757575",
  },
}));

export default function CardCounter({ icon, title, value, loading }) {
  const { card, cardContent, cardSubtitle, cardTitle } = useStyles();
  return !loading ? (
    <div className={card}>
      <div className={cardContent}>
        <i
          style={{ color: "#757575", fontSize: "2.875rem", display: "flex" }}
          className={icon}
        ></i>
        <div>
          <Typography variant="h6" component="h2" className={cardTitle}>
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={cardSubtitle}
          >
            {value}
          </Typography>
        </div>
      </div>
    </div>
  ) : (
    <Skeleton variant="rect" height={80} />
  );
}
