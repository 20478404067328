import React from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Card } from "../../components/Card";

const CampaignReportModal = ({ open, onClose, campaignName, children }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      scroll="paper"
      aria-labelledby="confirm-dialog"
    >
      <Card title={`Relatório da ${campaignName || "Campanha"}`}>
        <DialogContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "1.875rem 0 0 0",
            overflow: "hidden",
          }}
        >
          {children}
        </DialogContent>
      </Card>
    </Dialog>
  );
};

export default CampaignReportModal;
