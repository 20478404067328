import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Card } from "../../components/Card/index";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#fff",
    backgroundColor: "#9747FF",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
  btnCancel: {
    color: theme.palette.error.main,
    border: "1px solid red",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: "#fff",
    },
  },
}));

const ConfirmationModal = ({ title, children, open, onClose, onConfirm }) => {
  const { btn, btnCancel } = useStyles();

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="confirm-dialog"
    >
      <Card fullWidth title={title}>
        <DialogContent style={{ padding: "1.875rem 0" }}>
          <Typography
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "start",
              fontSize: "0.813rem",
              color: "#757575",
              width: "24.625rem",
            }}
          >
            {children}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            className={btnCancel}
            onClick={() => onClose(false)}
          >
            {i18n.t("confirmationModal.buttons.cancel")}
          </Button>
          <Button
            variant="contained"
            className={btn}
            onClick={() => {
              onClose(false);
              onConfirm();
            }}
          >
            {i18n.t("confirmationModal.buttons.confirm")}
          </Button>
        </DialogActions>
      </Card>
      {/* <DialogTitle id="confirm-dialog">{title}</DialogTitle> */}
    </Dialog>
  );
};

export default ConfirmationModal;
