import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, FieldArray, Form, Field } from "formik";
import { toast } from "react-toastify";
import { head } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Card } from "../../components/Card";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { SwitchCustom } from "../../components/ui/switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { parseISO } from "date-fns";
import { TextareaAutosize } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    flex: 1,
  },
  extraAttr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  btn: {
    color: "#fff",
    backgroundColor: "#9747FF",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
  btnAddInfo: {
    color: "#523197",
    border: "0.063rem solid #523197",
    "&:hover": {
      backgroundColor: "#523197",
      color: "#fff",
    },
  },
  attachmentBtn: {
    color: "#fff",
    backgroundColor: "#757575",
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  btnCancel: {
    color: theme.palette.error.main,
    border: "0.063rem solid red",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: "#fff",
    },
  },
}));

const formInputStyle = makeStyles((theme) => ({
  root: {
    "& label": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e0e0e0",
      },
    },
  },
}));

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  number: Yup.string().min(8, "Too Short!").max(50, "Too Long!"),
  email: Yup.string().email("Invalid email"),
});

const ContactModal = ({
  open,
  onClose,
  contactId,
  initialValues,
  onSave,
  selectedContact,
}) => {
  const classes = useStyles();
  const formInput = formInputStyle();
  const isMounted = useRef(true);
  const attachmentFile = useRef(null);

  const initialState = {
    name: selectedContact?.name || "",
    number: selectedContact?.number || "",
    email: selectedContact?.email || "",
    birthdayDate: selectedContact?.birthdayDate || "",
    birthdayMessage: selectedContact?.birthdayMessage || "",
    birthdayImage: selectedContact?.birthdayImage || "",
    disableBot: selectedContact?.disableBot || false,
    extraInfo: [],
    enableBirthMessage: selectedContact?.enableBirthMessage ? true : false,
  };

  const [birth, setBirth] = useState(!!selectedContact?.enableBirthMessage);
  const handleSwitchChange = (event) => {
    setBirth(event.target.checked);
  };
  const [attachment, setAttachment] = useState(null);

  const [contact, setContact] = useState({
    ...initialState,
    extraInfo: initialState || [],
  });

  useEffect(() => {
    if (selectedContact && selectedContact.birthdayDate) {
      setBirth(true);
    }
  }, [selectedContact]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const fetchContact = async () => {
      if (initialValues) {
        setContact((prevState) => {
          return { ...prevState, ...initialValues };
        });
      }

      if (!contactId) return;

      try {
        const { data } = await api.get(`/contacts/${contactId}`);
        if (isMounted.current) {
          const formatedDate = data.birthdayDate.slice(0, 10);
          setContact({ ...data, birthdayDate: formatedDate });
        }
      } catch (err) {
        toastError(err);
      }
    };

    fetchContact();
  }, [contactId, open, initialValues]);

  const handleClose = () => {
    onClose();
    setContact(initialState);
    setBirth(false);
  };

  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFile.current.value = null;
    }
    if (selectedContact?.birthdayImage) {
      setContact({ ...contact, birthdayImage: null });
    }
  };

  const handleSaveContact = async (values) => {
    const birthdayDateObj = parseISO(values.birthdayDate);

    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("number", values.number);
    formData.append("email", values.email);
    birth && formData.append("birthdayDate", birthdayDateObj);
    birth && formData.append("birthdayMessage", values.birthdayMessage);
    birth && attachment && formData.append("file", attachment);
    formData.append("disableBot", values.disableBot);
    formData.append("enableBirthMessage", birth);

    try {
      if (contactId) {
        await api.put(`/contacts/${contactId}`, formData);
        handleClose();
      } else {
        const { data } = await api.post("/contacts", formData);
        if (onSave) {
          onSave(data);
        }
        handleClose();
      }
      toast.success(i18n.t("contactModal.success"));
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <Card
          title={
            contactId
              ? `${i18n.t("contactModal.title.edit")}`
              : `${i18n.t("contactModal.title.add")}`
          }
        >
          <Formik
            initialValues={contact}
            enableReinitialize={true}
            validationSchema={ContactSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveContact(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form>
                <DialogContent>
                  <Typography variant="subtitle1" gutterBottom>
                    {i18n.t("contactModal.form.mainInfo")}
                  </Typography>
                  <div style={{ display: "flex" }}>
                    <Field
                      as={TextField}
                      label={i18n.t("contactModal.form.name")}
                      name="name"
                      fullWidth
                      autoFocus
                      style={{ marginRight: "0.652rem" }}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      classes={formInput}
                    />
                    <Field
                      as={TextField}
                      label={i18n.t("contactModal.form.number")}
                      name="number"
                      fullWidth
                      classes={formInput}
                      error={touched.number && Boolean(errors.number)}
                      helperText={touched.number && errors.number}
                      placeholder="5513912344321"
                      variant="outlined"
                      margin="dense"
                    />
                  </div>
                  <div>
                    <Field
                      as={TextField}
                      label={i18n.t("contactModal.form.email")}
                      name="email"
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      placeholder="Email address"
                      fullWidth
                      classes={formInput}
                      margin="dense"
                      variant="outlined"
                    />
                  </div>

                  <FormControlLabel
                    control={
                      <SwitchCustom
                        sx={{ m: 1 }}
                        onChange={() =>
                          setContact({
                            ...contact,
                            disableBot: !contact.disableBot,
                          })
                        }
                      />
                    }
                    label="Desabilitar chatbot para esse contato"
                  />

                  <FormControlLabel
                    control={
                      <SwitchCustom
                        sx={{ m: 1 }}
                        defaultChecked={
                          birth || selectedContact?.birthdayDate ? true : false
                        }
                        onChange={handleSwitchChange}
                      />
                    }
                    label="Habilitar disparo para aniversario"
                  />
                  <div style={{ display: "none" }}>
                    <input
                      type="file"
                      name="birthdayImage"
                      id="customFile"
                      accept=".png,.jpg,.jpeg,.mp4,.mp3,.ogg"
                      ref={attachmentFile}
                      onChange={(e) => handleAttachmentFile(e)}
                    />
                  </div>
                  {birth && (
                    <div
                      style={{
                        display: "flex",
                        padding: "0.625rem 0",
                        flexDirection: "column",
                        gap: "0.313rem",
                      }}
                    >
                      <Field
                        as={TextField}
                        classes={formInput}
                        label={"Data de Aniversário"}
                        name="birthdayDate"
                        error={
                          touched.scheduledAt && Boolean(errors.scheduledAt)
                        }
                        helperText={touched.scheduledAt && errors.scheduledAt}
                        variant="outlined"
                        margin="dense"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                      />

                      <Field
                        as={TextareaAutosize}
                        name="birthdayMessage"
                        classes={formInput}
                        fullWidth
                        minRows={5}
                        maxRows={6}
                        label={"mensagem de aniverário aqui"}
                        placeholder={i18n.t("Mensagem*")}
                        multiline={true}
                        variant="outlined"
                      />
                      {attachment || contact.birthdayImage ? (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Button
                            size="small"
                            startIcon={
                              <i
                                style={{ color: "gray" }}
                                className={"fi-rr-clip"}
                              ></i>
                            }
                          >
                            {attachment?.name || selectedContact?.birthdayImage}
                          </Button>

                          <IconButton
                            size="small"
                            onClick={deleteMedia}
                            color="secondary"
                          >
                            <i
                              style={{ color: "red" }}
                              className={"fi-rr-trash"}
                            ></i>
                          </IconButton>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <Button
                            color="primary"
                            className={classes.attachmentBtn}
                            onClick={() => attachmentFile.current.click()}
                            disabled={isSubmitting}
                            variant="outlined"
                          >
                            {i18n.t("campaigns.dialog.buttons.attach")}
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  <Typography
                    style={{ marginBottom: 8, marginTop: 12 }}
                    variant="subtitle1"
                  >
                    {i18n.t("contactModal.form.extraInfo")}
                  </Typography>

                  <FieldArray name="extraInfo">
                    {({ push, remove }) => (
                      <>
                        {values.extraInfo &&
                          values.extraInfo.length > 0 &&
                          values.extraInfo.map((info, index) => (
                            <div
                              className={classes.extraAttr}
                              key={`${index}-info`}
                            >
                              <Field
                                as={TextField}
                                label={i18n.t("contactModal.form.extraName")}
                                name={`extraInfo[${index}].name`}
                                variant="outlined"
                                margin="dense"
                                classes={formInput}
                                className={classes.textField}
                              />
                              <Field
                                as={TextField}
                                label={i18n.t("contactModal.form.extraValue")}
                                name={`extraInfo[${index}].value`}
                                variant="outlined"
                                margin="dense"
                                classes={formInput}
                                className={classes.textField}
                              />
                              <IconButton
                                size="medium"
                                onClick={() => remove(index)}
                              >
                                <i
                                  style={{ color: "#523197" }}
                                  className={"fi-rr-trash"}
                                ></i>
                              </IconButton>
                            </div>
                          ))}
                        <div className={classes.extraAttr}>
                          <Button
                            style={{ flex: 1, marginTop: 8 }}
                            variant="outlined"
                            className={classes.btnAddInfo}
                            color="primary"
                            onClick={() => push({ name: "", value: "" })}
                          >
                            {`+ ${i18n.t("contactModal.buttons.addExtraInfo")}`}
                          </Button>
                        </div>
                      </>
                    )}
                  </FieldArray>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    disabled={isSubmitting}
                    className={classes.btnCancel}
                    variant="outlined"
                  >
                    {i18n.t("contactModal.buttons.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btn}
                  >
                    {contactId
                      ? `${i18n.t("contactModal.buttons.okEdit")}`
                      : `${i18n.t("contactModal.buttons.okAdd")}`}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Card>
      </Dialog>
    </div>
  );
};

export default ContactModal;
