import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import moment from "moment"; // Importe a biblioteca moment
import { Card } from "../../components/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "100vh",
  },
  paper: {
    padding: theme.spacing(3),
    maxWidth: 600,
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  emailSection: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
  },
  btn: {
    color: "#fff",
    backgroundColor: "#9747FF",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
}));

const formInputStyle = makeStyles((theme) => ({
  root: {
    "& label": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e0e0e0",
      },
    },
  },
}));

const EmailScheduler = () => {
  const classes = useStyles();
  const inputsClasses = formInputStyle();

  const [sender, setSender] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emails, setEmails] = useState([]);
  const [selectedDate, setSelectedDate] = useState(""); // Altere para uma string para a entrada de texto

  const handleSendEmail = async () => {
    if (subject && message && selectedDate) {
      const senderEmails = sender.split(",").map((email) => email.trim());

      try {
        for (const email of senderEmails) {
          const formattedDate = moment(selectedDate).format("YYYY-MM-DDTHH:mm"); // Formata a data e hora

          console.log("Valor de selectedDate:", selectedDate);

          const response = await api.post(
            `${process.env.REACT_APP_BACKEND_URL}/agendar-envio-email`,
            {
              email,
              tokenSenha: "",
              assunto: subject,
              mensagem: message,
              sendAt: formattedDate,
            }
          );

          console.log("Resposta da API para", email, ":", response.data);

          setEmails([...emails, { sender: email, subject, message }]);
        }

        setSender("");
        setSubject("");
        setMessage("");
        setSelectedDate("");
      } catch (error) {
        console.error("Erro na API:", error);
      }
    }
  };

  return (
    <Container component="main" maxWidth="lg" className={classes.container}>
      <Card title="AGENDAMENTO DE EMAILS">
        <form className={classes.form}>
          <TextField
            label="Destinatario (separe por vírgula)"
            value={sender}
            onChange={(e) => setSender(e.target.value)}
            fullWidth
            classes={inputsClasses}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            label="Assunto"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            fullWidth
            classes={inputsClasses}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            label="Mensagem"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            fullWidth
            classes={inputsClasses}
            margin="normal"
            variant="outlined"
            required
          />
          <TextField
            label="" // Instruções para o formato
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            type="datetime-local"
            fullWidth
            classes={inputsClasses}
            margin="normal"
            variant="outlined"
            required
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendEmail}
            fullWidth
            className={classes.btn}
          >
            Agendar Envio
          </Button>
        </form>
      </Card>
    </Container>
  );
};

export default EmailScheduler;
