import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ColorBox } from "material-ui-color";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { IconButton, InputAdornment } from "@material-ui/core";
import { Card } from "../../components/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  colorAdorment: {
    width: 20,
    height: 20,
  },
  btnCancel: {
    color: theme.palette.error.main,
    border: "0.063rem solid red",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: "#fff",
    },
  },
  btn: {
    color: "#fff",
    backgroundColor: "#9747FF",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
}));

const formInputStyle = makeStyles((theme) => ({
  root: {
    "& label": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e0e0e0",
      },
    },
  },
}));

const TagSchema = Yup.object().shape({
  name: Yup.string().min(3, "Mensagem muito curta").required("Obrigatório"),
});

const TagModal = ({ open, onClose, tagId, reload, kanban }) => {
  const classes = useStyles();
  const inputClasses = formInputStyle();

  const { user } = useContext(AuthContext);
  const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);

  const initialState = {
    name: "",
    color: "",
    kanban: kanban,
  };

  const [tag, setTag] = useState(initialState);

  useEffect(() => {
    try {
      (async () => {
        if (!tagId) return;

        const { data } = await api.get(`/tags/${tagId}`);
        setTag((prevState) => {
          return { ...prevState, ...data };
        });
      })();
    } catch (err) {
      toastError(err);
    }
  }, [tagId, open]);

  const handleClose = () => {
    setTag(initialState);
    setColorPickerModalOpen(false);
    onClose();
  };

  const handleSaveTag = async (values) => {
    const tagData = { ...values, userId: user.id, kanban: kanban };

    try {
      if (tagId) {
        await api.put(`/tags/${tagId}`, tagData);
      } else {
        await api.post("/tags", tagData);
      }
      toast.success(
        kanban === 0
          ? `${i18n.t("tagModal.success")}`
          : `${i18n.t("tagModal.successKanban")}`
      );
      if (typeof reload == "function") {
        reload();
      }
    } catch (err) {
      toastError(err);
    }
    handleClose();
  };

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: "red", borderRadius: "0.625rem" }}
    >
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <Card
          fullWidth
          title={
            tagId
              ? kanban === 0
                ? `${i18n.t("tagModal.title.edit")}`
                : `${i18n.t("EDITAR TAG")}`
              : kanban === 0
              ? `${i18n.t("tagModal.title.add")}`
              : `${i18n.t("NOVA TAG")}`
          }
        >
          <Formik
            initialValues={tag}
            enableReinitialize={true}
            validationSchema={TagSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveTag(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting, values }) => (
              <Form>
                <DialogContent>
                  <div className={classes.multFieldLine}>
                    <Field
                      as={TextField}
                      classes={inputClasses}
                      label={i18n.t("tagModal.form.name")}
                      name="name"
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      variant="outlined"
                      margin="dense"
                      onChange={(e) =>
                        setTag((prev) => ({ ...prev, name: e.target.value }))
                      }
                      fullWidth
                    />
                  </div>
                  <br />
                  <div className={classes.multFieldLine}>
                    <Field
                      as={TextField}
                      classes={inputClasses}
                      fullWidth
                      label={i18n.t("tagModal.form.color")}
                      name="color"
                      id="color"
                      error={touched.color && Boolean(errors.color)}
                      helperText={touched.color && errors.color}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div
                              style={{ backgroundColor: values.color }}
                              className={classes.colorAdorment}
                            ></div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <IconButton
                            size="small"
                            color="default"
                            onClick={() =>
                              setColorPickerModalOpen(!colorPickerModalOpen)
                            }
                          >
                            <i
                              style={{ color: "gray" }}
                              className={"fi-rr-paint-brush"}
                            ></i>
                            {/* <Colorize /> */}
                          </IconButton>
                        ),
                      }}
                      variant="outlined"
                      margin="dense"
                    />
                  </div>

                  {colorPickerModalOpen && (
                    <div>
                      <ColorBox
                        disableAlpha={true}
                        hslGradient={false}
                        style={{ margin: "1.25rem auto 0" }}
                        value={tag.color}
                        onChange={(val) => {
                          setTag((prev) => ({ ...prev, color: `#${val.hex}` }));
                        }}
                      />
                    </div>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    className={classes.btnCancel}
                    disabled={isSubmitting}
                    variant="outlined"
                  >
                    {i18n.t("tagModal.buttons.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    className={classes.btn}
                  >
                    {tagId
                      ? `${i18n.t("tagModal.buttons.okEdit")}`
                      : `${i18n.t("tagModal.buttons.okAdd")}`}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Card>
        {/* <DialogTitle id="form-dialog-title">
          {tagId
            ? kanban === 0
              ? `${i18n.t("tagModal.title.edit")}`
              : `${i18n.t("EDITAR TAG")}`
            : kanban === 0
            ? `${i18n.t("tagModal.title.add")}`
            : `${i18n.t("NOVA TAG")}`}
        </DialogTitle> */}
      </Dialog>
    </div>
  );
};

export default TagModal;
