import React, { useState, useEffect, useContext, useRef } from "react"; // Note que useEffect agora está sendo importado no início

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import ConfirmationModal from "../ConfirmationModal"; // Certifique-se de que o caminho do arquivo está correto
import AttachFileIcon from "@material-ui/icons/AttachFile";

//import agendamento adc
import { head } from "lodash";
import { Grid, IconButton } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

//fim

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { FormControl } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray, capitalize } from "lodash";
import { Card } from "../../components/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  btn: {
    color: "#fff",
    backgroundColor: "#9747FF",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
  attachmentBtn: {
    color: "#fff",
    backgroundColor: "#757575",
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  dropdownBtn: {
    color: "#757575",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#523197",
    },
  },
  btnCancel: {
    color: theme.palette.error.main,
    border: "0.063rem solid red",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
      color: "#fff",
    },
  },
}));

const formInputStyle = makeStyles((theme) => ({
  root: {
    "& label": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e0e0e0",
      },
    },
  },
}));

const ScheduleSchema = Yup.object().shape({
  body: Yup.string().min(5, "Mensagem muito curta").required("Obrigatório"),
  contactId: Yup.number().required("Obrigatório"),
  sendAt: Yup.string().required("Obrigatório"),
});

const ScheduleModal = ({
  open,
  onClose,
  scheduleId,
  contactId,
  cleanContact,
  reload,
}) => {
  const classes = useStyles();
  const formInput = formInputStyle();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const initialState = {
    body: "",
    contactId: "",
    sendAt: moment().add(1, "hour").format("YYYY-MM-DDTHH:mm"),
    sentAt: "",
  };

  const initialContact = {
    id: "",
    name: "",
  };

  const [schedule, setSchedule] = useState(initialState);
  const [currentContact, setCurrentContact] = useState(initialContact);
  const [contacts, setContacts] = useState([initialContact]);
  const [attachment, setAttachment] = useState(null);
  const attachmentFile = useRef(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  useEffect(() => {
    if (contactId && contacts.length) {
      const contact = contacts.find((c) => c.id === contactId);
      if (contact) {
        setCurrentContact(contact);
      }
    }
  }, [contactId, contacts]);

  useEffect(() => {
    const { companyId } = user;
    if (open) {
      try {
        (async () => {
          const { data: contactList } = await api.get("/contacts/list", {
            params: { companyId: companyId },
          });
          let customList = contactList.map((c) => ({ id: c.id, name: c.name }));
          if (isArray(customList)) {
            setContacts([{ id: "", name: "" }, ...customList]);
          }
          if (contactId) {
            setSchedule((prevState) => {
              return { ...prevState, contactId };
            });
          }

          if (!scheduleId) return;

          const { data } = await api.get(`/schedules/${scheduleId}`);
          setSchedule((prevState) => {
            return {
              ...prevState,
              ...data,
              sendAt: moment(data.sendAt).format("YYYY-MM-DDTHH:mm"),
            };
          });
          setCurrentContact(data.contact);
        })();
      } catch (err) {
        toastError(err);
      }
    }
  }, [scheduleId, contactId, open, user]);

  const handleClose = () => {
    onClose();
    setSchedule(initialState);
  };

  const handleAttachmentFile = (e) => {
    const file = head(e.target.files);
    if (file) {
      setAttachment(file);
    }
  };

  const handleSaveSchedule = async (values) => {
    const scheduleData = { ...values, userId: user.id };
    try {
      if (scheduleId) {
        await api.put(`/schedules/${scheduleId}`, scheduleData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${scheduleId}/media-upload`, formData);
        }
      } else {
        const { data } = await api.post("/schedules", scheduleData);
        if (attachment != null) {
          const formData = new FormData();
          formData.append("file", attachment);
          await api.post(`/schedules/${data.id}/media-upload`, formData);
        }
      }
      toast.success(i18n.t("scheduleModal.success"));
      if (typeof reload == "function") {
        reload();
      }
      if (contactId) {
        if (typeof cleanContact === "function") {
          cleanContact();
          history.push("/schedules");
        }
      }
    } catch (err) {
      toastError(err);
    }
    setCurrentContact(initialContact);
    setSchedule(initialState);
    handleClose();
  };

  const deleteMedia = async () => {
    if (attachment) {
      setAttachment(null);
      attachmentFile.current.value = null;
    }

    if (schedule.mediaPath) {
      await api.delete(`/schedules/${schedule.id}/media-upload`);
      setSchedule((prev) => ({
        ...prev,
        mediaPath: null,
      }));
      toast.success(i18n.t("scheduleModal.toasts.deleted"));
      if (typeof reload == "function") {
        console.log(reload);
        console.log("1");
        reload();
      }
    }
  };

  return (
    <div className={classes.root}>
      <ConfirmationModal
        title={i18n.t("scheduleModal.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={deleteMedia}
      >
        {i18n.t("scheduleModal.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        scroll="paper"
      >
        <Card
          fullWidth
          title={
            schedule.status === "ERRO"
              ? "Erro de Envio"
              : `Mensagem ${capitalize(schedule.status)}`
          }
        >
          <div style={{ display: "none" }}>
            <input
              type="file"
              accept=".png,.jpg,.jpeg,.mp4,.mp3,.ogg"
              ref={attachmentFile}
              onChange={(e) => handleAttachmentFile(e)}
            />
          </div>
          <Formik
            initialValues={schedule}
            enableReinitialize={true}
            validationSchema={ScheduleSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveSchedule(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting, values }) => (
              <Form>
                <DialogContent
                  style={{
                    border: 0,
                    padding: 0,
                    overflowY: "none",
                    marginTop: "0.625rem",
                  }}
                >
                  <div className={classes.multFieldLine}>
                    <FormControl variant="outlined" fullWidth>
                      <Autocomplete
                        fullWidth
                        value={currentContact}
                        options={contacts}
                        onChange={(e, contact) => {
                          const contactId = contact ? contact.id : "";
                          setSchedule({ ...schedule, contactId });
                          setCurrentContact(contact ? contact : initialContact);
                        }}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => {
                          return value.id === option.id;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            classes={formInput}
                            variant="outlined"
                            placeholder="Contato"
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                  <br />
                  <div className={classes.multFieldLine}>
                    <Field
                      as={TextField}
                      rows={9}
                      multiline={true}
                      classes={formInput}
                      label={i18n.t("scheduleModal.form.body")}
                      name="body"
                      error={touched.body && Boolean(errors.body)}
                      helperText={touched.body && errors.body}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                  <br />
                  <div className={classes.multFieldLine}>
                    <Field
                      as={TextField}
                      label={i18n.t("scheduleModal.form.sendAt")}
                      type="datetime-local"
                      name="sendAt"
                      classes={formInput}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={touched.sendAt && Boolean(errors.sendAt)}
                      helperText={touched.sendAt && errors.sendAt}
                      variant="outlined"
                      fullWidth
                    />
                  </div>
                  {(schedule.mediaPath || attachment) && (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        size="small"
                        startIcon={
                          <i
                            style={{ color: "gray" }}
                            className={"fi-rr-clip"}
                          ></i>
                        }
                      >
                        {attachment ? attachment.name : schedule.mediaName}
                      </Button>

                      <IconButton
                        size="small"
                        onClick={() => setConfirmationOpen(true)}
                        color="secondary"
                      >
                        <i
                          style={{ color: "red" }}
                          className={"fi-rr-trash"}
                        ></i>
                      </IconButton>
                    </div>
                  )}
                </DialogContent>
                <DialogActions
                  style={{
                    paddingTop: "0.625rem",
                    flexDirection: "column",
                    gap: "0.313rem",
                  }}
                >
                  {!attachment && !schedule.mediaPath && (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                    >
                      <Button
                        color="primary"
                        className={classes.attachmentBtn}
                        onClick={() => attachmentFile.current.click()}
                        disabled={isSubmitting}
                        variant="outlined"
                      >
                        {i18n.t("campaigns.dialog.buttons.attach")}
                      </Button>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      gap: "0.313rem",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      disabled={isSubmitting}
                      className={classes.btnCancel}
                      variant="outlined"
                    >
                      {i18n.t("scheduleModal.buttons.cancel")}
                    </Button>
                    {(schedule.sentAt === null || schedule.sentAt === "") && (
                      <Button
                        type="submit"
                        className={classes.btn}
                        color="primary"
                        disabled={isSubmitting}
                        variant="contained"
                      >
                        {scheduleId
                          ? `${i18n.t("scheduleModal.buttons.okEdit")}`
                          : `${i18n.t("scheduleModal.buttons.okAdd")}`}
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </Button>
                    )}
                  </div>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Card>
      </Dialog>
    </div>
  );
};

export default ScheduleModal;
