import React from "react";
import {
  makeStyles,
  Drawer,
  List,
  Divider,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import MainListItems from "../../layout/MainListItems";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import logo from "../../assets/logo.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    minHeight: "48px",
    [theme.breakpoints.down("sm")]: {
      height: "48px",
    },
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    ...theme.scrollbarStylesSoft,
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  containerWithScroll: {
    flex: 1,
    padding: theme.spacing(2),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  lowerContainer: {
    width: "5.25rem",
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  logoClass: {
    width: "80%",
    height: "auto",
    maxWidth: 180,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      height: "80%",
      maxWidth: 180,
    },
    logo: theme.logo,
  },
  btnWithHover: {
    width: "2.625rem",
    height: "2.625rem",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
}));

export const Sidebar = ({
  drawerVariant,
  drawerOpen,
  setDrawerOpen,
  drawerClose,
}) => {
  const {
    drawerPaper,
    drawerPaperClose,
    toolbarIcon,
    logoClass,
    containerWithScroll,
    lowerContainer,
  } = useStyles();
  return (
    <Drawer
      variant={drawerVariant}
      className={drawerOpen ? drawerPaper : drawerPaperClose}
      classes={{
        paper: clsx(drawerPaper, !drawerOpen && drawerPaperClose),
      }}
      open={drawerOpen}
    >
      <div className={toolbarIcon}>
        <img src={logo} className={logoClass} alt="logo" />
        <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List className={drawerOpen ? containerWithScroll : lowerContainer}>
        <MainListItems
          drawerClose={drawerClose}
          collapsed={!drawerOpen}
          drawerOpen={drawerOpen}
        />
      </List>
      <Divider />
    </Drawer>
  );
};
