import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { toast } from "react-toastify";
import { Grid, LinearProgress, Typography } from "@material-ui/core";
import api from "../../services/api";
import { has, get, isNull } from "lodash";
import CardCounter from "../../components/Dashboard/CardCounter";
import DoneIcon from "@material-ui/icons/Done";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { useDate } from "../../hooks/useDate";
import usePlans from "../../hooks/usePlans";

import { socketConnection } from "../../services/socket";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.padding,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  textRight: {
    textAlign: "right",
  },
  tabPanelsContainer: {
    padding: theme.padding,
  },
}));

const useProgressStyles = makeStyles((theme) => ({
  root: {
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#9747FF",
      borderRadius: 5,
    },
  },
}));

const CampaignReport = ({ campaignId }) => {
  const classes = useStyles();
  const progressClass = useProgressStyles();
  const history = useHistory();

  const [campaign, setCampaign] = useState({});
  const [validContacts, setValidContacts] = useState(0);
  const [delivered, setDelivered] = useState(0);
  const [confirmationRequested, setConfirmationRequested] = useState(0);
  const [confirmed, setConfirmed] = useState(0);
  const [percent, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const mounted = useRef(true);

  const { datetimeToClient } = useDate();
  const { getPlanCompany } = usePlans();

  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      const planConfigs = await getPlanCompany(undefined, companyId);
      if (!planConfigs.plan.useCampaigns) {
        toast.error(
          "Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando."
        );
        setTimeout(() => {
          history.push(`/`);
        }, 1000);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mounted.current) {
      findCampaign();
    }

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mounted.current && has(campaign, "shipping")) {
      if (has(campaign, "contactList")) {
        const contactList = get(campaign, "contactList");
        const valids = contactList.contacts.filter((c) => c.isWhatsappValid);
        setValidContacts(valids.length);
      }

      if (has(campaign, "shipping")) {
        const contacts = get(campaign, "shipping");
        const delivered = contacts.filter((c) => !isNull(c.deliveredAt));
        const confirmationRequested = contacts.filter(
          (c) => !isNull(c.confirmationRequestedAt)
        );
        const confirmed = contacts.filter(
          (c) => !isNull(c.deliveredAt) && !isNull(c.confirmationRequestedAt)
        );
        setDelivered(delivered.length);
        setConfirmationRequested(confirmationRequested.length);
        setConfirmed(confirmed.length);
        setDelivered(delivered.length);
      }
    }
  }, [campaign]);

  useEffect(() => {
    setPercent((delivered / validContacts) * 100);
  }, [delivered, validContacts]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-campaign`, (data) => {
      console.log(data);
      if (data.record.id === +campaignId) {
        setCampaign(data.record);

        if (data.record.status === "FINALIZADA") {
          setTimeout(() => {
            findCampaign();
          }, 5000);
        }
      }
    });

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const findCampaign = async () => {
    setLoading(true);
    const { data } = await api.get(`/campaigns/${campaignId}`);
    setCampaign(data);
    setLoading(false);
  };

  const formatStatus = (val) => {
    switch (val) {
      case "INATIVA":
        return "Inativa";
      case "PROGRAMADA":
        return "Programada";
      case "EM_ANDAMENTO":
        return "Em Andamento";
      case "CANCELADA":
        return "Cancelada";
      case "FINALIZADA":
        return "Finalizada";
      default:
        return val;
    }
  };

  return (
    <Paper
      style={{
        boxShadow: "none",
        padding: 0,
        overflow: "hidden",
        border: "none",
        width: "auto",
      }}
      className={classes.mainPaper}
      variant="outlined"
    >
      <Grid spacing={2} style={{ width: "100%" }} container>
        <Grid xs={12} md={4} item>
          <CardCounter
            icon="fi-sr-user"
            title="Contatos Válidos"
            value={validContacts}
            loading={loading}
          />
        </Grid>
        {campaign.confirmation && (
          <>
            <Grid xs={12} md={4} item>
              <CardCounter
                icon={<DoneIcon fontSize="inherit" />}
                title="Confirmações Solicitadas"
                value={confirmationRequested}
                loading={loading}
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <CardCounter
                icon={<DoneAllIcon fontSize="inherit" />}
                title="Confirmações"
                value={confirmed}
                loading={loading}
              />
            </Grid>
          </>
        )}
        <Grid xs={12} md={4} item>
          <CardCounter
            icon="fi-sr-checkbox"
            title="Entregues"
            value={delivered}
            loading={loading}
          />
        </Grid>
        {campaign.whatsappId && (
          <Grid xs={12} md={4} item>
            <CardCounter
              icon="fi-brands-whatsapp"
              title="Conexão"
              value={campaign.whatsapp.name}
              loading={loading}
            />
          </Grid>
        )}
        {campaign.contactListId && (
          <Grid xs={12} md={4} item>
            <CardCounter
              icon="fi-rr-list"
              title="Lista de Contatos"
              value={campaign.contactList.name}
              loading={loading}
            />
          </Grid>
        )}
        <Grid xs={12} md={4} item>
          <CardCounter
            icon="fi-sr-clock"
            title="Agendamento"
            value={datetimeToClient(campaign.scheduledAt)}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} md={4} item>
          <CardCounter
            icon="fi-sr-calendar"
            title="Conclusão"
            value={datetimeToClient(campaign.completedAt)}
            loading={loading}
          />
        </Grid>
        <Grid xs={12} item>
          <LinearProgress
            variant="determinate"
            classes={progressClass}
            style={{
              height: 36,
              borderRadius: 5,
              margin: "20px 0",
              backgroundColor: "#e0e0e0",
            }}
            value={percent}
          />
          <div style={{ position: "absolute" }}>
            <Typography
              style={{
                fontSize: 13,
                position: "relative",
                top: -47,
                left: 10,
                ...(!percent ? { color: "#9747FF" } : { color: "#fff" }),
              }}
              variant="h6"
              component="h2"
            >
              Status: {formatStatus(campaign.status)} {delivered} de{" "}
              {validContacts}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CampaignReport;
