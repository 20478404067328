import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Collapse from "@material-ui/core/Collapse";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import api from "../../services/api";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { Card as LayoutCard } from "../../components/Card";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2rem",
  },
  inputContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "1rem",
    marginTop: "0.625rem",
  },
  input: {
    flex: "1 0 40%",
    marginRight: "1rem",
  },
  descriptionInput: {
    flex: "1 0 40%", // Ajuste a largura máxima conforme necessário
  },
  button: {
    flex: "1 0 20%",
  },
  listContainer: {
    width: "100%",
    marginTop: "1rem",
  },
  list: {
    marginBottom: "5px",
    backgroundColor: "#dfdfdf",
    color: "black",
    cursor: "pointer",
    "&.completedTask": {
      backgroundColor: "#c5f7c5", // Cor de fundo para tarefas concluídas
    },
  },
  description: {
    background: "#dfdfdf",
    padding: "8px",
    borderRadius: "4px",
    whiteSpace: "pre-wrap",
    maxHeight: "100px",
    overflowY: "auto",
  },
  separator: {
    width: "100%",
    borderBottom: "1px solid gray",
  },
  error: {
    display: "flex",
    justifyContent: "center",
    color: "red",
  },
  card: {
    margin: "8px 0",
  },
  btnAddInfo: {
    width: "100%",
    color: "#523197",
    border: "0.063rem solid #523197",
    "&:hover": {
      backgroundColor: "#523197",
      color: "#fff",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "1.25rem 0 0 0",
    gap: "0.625rem",
    alignItems: "center",
  },
  task: {
    display: "flex",
    backgroundColor: "#fff",
    width: "41.375rem",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.625rem",
    flexDirection: "column",
    borderRadius: "0.625rem 0.625rem 0 0",
    border: "0.063rem solid #e0e0e0",
  },
  childTask: {
    position: "relative",
    padding: "0.625rem",
    top: "-0.65rem",
    width: "41.375rem",
    maxWidth: 800,
    backgroundColor: "#f1f1f1",
    borderRadius: "0 0 0.625rem 0.625rem",
  },
}));

const formInputStyle = makeStyles((theme) => ({
  root: {
    "& label": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e0e0e0",
      },
    },
  },
}));

// const fakeTasks = [
//   {
//     id: 1,
//     text: "Tarefa 1",
//     description: "Descrição da tarefa 1",
//     completed: false,
//   },
//   {
//     id: 2,
//     text: "Tarefa 2",
//     description: "Descrição da tarefa 2",
//     completed: true,
//   },
//   {
//     id: 3,
//     text: "Tarefa 3",
//     description: "Descrição da tarefa 3",
//     completed: false,
//   },
// ];

const ToDoList = () => {
  const classes = useStyles();
  const formInput = formInputStyle();

  const [task, setTask] = useState("");
  const [description, setDescription] = useState("");
  const [tasks, setTasks] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);
  const [error, setError] = useState(null);
  const [expandedTask, setExpandedTask] = useState(null);

  const fetchTasks = async () => {
    try {
      const response = await api.get("/tasks");
      setTasks(response.data);
      setError(null);
    } catch (error) {
      if (error.message === "Network Error") {
        setError(
          "Erro de rede ao buscar tarefas. Verifique sua conexão à internet."
        );
      } else {
        console.error("Erro ao buscar as tarefas:", error);
        setError("Erro ao buscar as tarefas. Tente novamente mais tarde.");
      }
    }
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  const handleTaskChange = (event) => {
    setTask(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleAddTask = async () => {
    if (!task.trim()) {
      return;
    }

    const now = new Date();
    if (editIndex >= 0) {
      const updatedTask = {
        ...tasks[editIndex],
        text: task,
        description: description,
      };
      try {
        await api.put(`/tasks/${updatedTask.id}`, updatedTask);
        const updatedTasks = [...tasks];
        updatedTasks[editIndex] = updatedTask;
        setTasks(updatedTasks);
        setTask("");
        setDescription("");
        setEditIndex(-1);
      } catch (error) {
        console.error("Erro ao atualizar a tarefa:", error);
        setError("Erro ao atualizar a tarefa.");
      }
    } else {
      const newTask = {
        text: task,
        description,
        created_at: now,
        updated_at: now,
      };
      try {
        const response = await api.post("/tasks", newTask);
        newTask.id = response.data.id;
        setTasks([...tasks, newTask]);
        setTask("");
        setDescription("");
      } catch (error) {
        console.error("Erro ao criar a tarefa:", error);
        setError("Erro ao criar a tarefa.");
      }
    }
  };

  const handleEditTask = (index) => {
    setTask(tasks[index].text);
    setDescription(tasks[index].description);
    setEditIndex(index);
  };

  const handleDeleteTask = async (index) => {
    const taskId = tasks[index].id;
    try {
      await api.delete(`/tasks/${taskId}`);
      const updatedTasks = [...tasks];
      updatedTasks.splice(index, 1);
      setTasks(updatedTasks);
      setError(null);
    } catch (error) {
      console.error("Erro ao excluir a tarefa:", error);
      setError("Erro ao excluir a tarefa.");
    }
  };

  return (
    <div className={classes.root}>
      <LayoutCard title="TAREFAS">
        <div className={classes.inputContainer}>
          <TextField
            className={classes.input}
            classes={formInput}
            label="Nova tarefa"
            value={task}
            onChange={handleTaskChange}
            variant="outlined"
          />
          <TextField
            className={classes.descriptionInput}
            classes={formInput}
            label="Descrição"
            value={description}
            onChange={handleDescriptionChange}
            variant="outlined"
            multiline
            rows={1}
          />
        </div>
        <Button
          className={classes.btnAddInfo}
          variant="contained"
          color="primary"
          onClick={handleAddTask}
        >
          {editIndex >= 0 ? "Salvar" : "+ Adicionar"}
        </Button>
      </LayoutCard>
      <div className={classes.listContainer}>
        {error && <div className={classes.error}>{error}</div>}
        <List>
          <div className={classes.cardContent}>
            {tasks.map((task, index) => (
              <React.Fragment key={task.id}>
                <ListItem
                  className={`${classes.task} ${
                    task.completed ? classes.completedTask : ""
                  }`}
                >
                  <ListItemText
                    style={{
                      width: "100%",
                    }}
                    primary={task.text}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      size="medium"
                      onClick={() =>
                        setExpandedTask(expandedTask === index ? null : index)
                      }
                    >
                      <i
                        style={{
                          color: "gray",
                          textAlign: "center",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        className={"fi-sr-angle-small-down"}
                      ></i>
                    </IconButton>
                    <IconButton onClick={() => handleEditTask(index)}>
                      <i
                        style={{ color: "gray" }}
                        className={"fi-rr-pencil"}
                      ></i>
                    </IconButton>
                    <IconButton onClick={() => handleDeleteTask(index)}>
                      <i
                        style={{ color: "gray" }}
                        className={"fi-rr-trash"}
                      ></i>
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                <Collapse in={expandedTask === index}>
                  <Card
                    style={{ boxShadow: "none", overflow: "none" }}
                    className={classes.childTask}
                  >
                    <CardContent style={{ padding: 0 }}>
                      <p>{task.description}</p>
                    </CardContent>
                  </Card>
                </Collapse>
                {index < tasks.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </div>
        </List>
      </div>
    </div>
  );
};

export default ToDoList;
