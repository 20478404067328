import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { toast } from "react-toastify";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import api from "../../services/api";
import usePlans from "../../hooks/usePlans";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import ConfirmationModal from "../../components/ConfirmationModal";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Card } from "../../components/Card";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    // padding: theme.padding,
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  textRight: {
    textAlign: "right",
  },
  tabPanelsContainer: {
    // padding: theme.spacing(2),
    padding: theme.padding,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
  },
  btn: {
    color: "#fff",
    backgroundColor: "#9747FF",
    "&:hover": {
      backgroundColor: "#523197",
    },
  },
  btnLink: {
    color: "#9747FF",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#9747FF",
    },
  },
  dropdownBtn: {
    color: "#757575",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#523197",
    },
  },
}));

const formInputStyle = makeStyles((theme) => ({
  root: {
    "& label": {
      color: "#757575",
    },
    "& label.Mui-focused": {
      color: "#757575",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#757575",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e0e0e0",
      },
      "&:hover fieldset": {
        borderColor: "#757575",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#e0e0e0",
      },
    },
  },
}));

const initialSettings = {
  messageInterval: 20,
  longerIntervalAfter: 20,
  greaterInterval: 60,
  variables: [],
};

const CampaignsConfig = () => {
  const classes = useStyles();
  const formInput = formInputStyle();
  const history = useHistory();

  const [settings, setSettings] = useState(initialSettings);
  const [showVariablesForm, setShowVariablesForm] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(null);
  const [variable, setVariable] = useState({ key: "", value: "" });

  const { getPlanCompany } = usePlans();

  useEffect(() => {
    async function fetchData() {
      const companyId = localStorage.getItem("companyId");
      const planConfigs = await getPlanCompany(undefined, companyId);
      if (!planConfigs.plan.useCampaigns) {
        toast.error(
          "Esta empresa não possui permissão para acessar essa página! Estamos lhe redirecionando."
        );
        setTimeout(() => {
          history.push(`/`);
        }, 1000);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    api.get("/campaign-settings").then(({ data }) => {
      const settingsList = [];
      if (Array.isArray(data) && data.length > 0) {
        data.forEach((item) => {
          settingsList.push([item.key, JSON.parse(item.value)]);
        });
        setSettings(Object.fromEntries(settingsList));
      }
    });
  }, []);

  const handleOnChangeVariable = (e) => {
    if (e.target.value !== null) {
      const changedProp = {};
      changedProp[e.target.name] = e.target.value;
      setVariable((prev) => ({ ...prev, ...changedProp }));
    }
  };

  const handleOnChangeSettings = (e) => {
    const changedProp = {};
    changedProp[e.target.name] = e.target.value;
    setSettings((prev) => ({ ...prev, ...changedProp }));
  };

  const addVariable = () => {
    setSettings((prev) => {
      const variablesExists = settings.variables.filter(
        (v) => v.key === variable.key
      );
      const variables = prev.variables;
      if (variablesExists.length === 0) {
        variables.push(Object.assign({}, variable));
        setVariable({ key: "", value: "" });
      }
      return { ...prev, variables };
    });
  };

  const removeVariable = () => {
    const newList = settings.variables.filter((v) => v.key !== selectedKey);
    setSettings((prev) => ({ ...prev, variables: newList }));
    setSelectedKey(null);
  };

  const saveSettings = async () => {
    await api.post("/campaign-settings", { settings });
    toast.success("Configurações salvas");
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={i18n.t("campaigns.confirmationModal.deleteTitle")}
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={removeVariable}
      >
        {i18n.t("campaigns.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <Box className={classes.tabPanelsContainer}>
        <Grid spacing={1} container>
          <Card fullWidth title={i18n.t("campaignsConfig.title")}>
            <Paper
              className={classes.mainPaper}
              style={{
                display: "flex",
                flexDirection: "column",
                boxShadow: "none",
                padding: 0,
                overflowY: "none",
                border: "none",
                marginTop: "0.625rem",
              }}
              variant="outlined"
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  margin: "0.625rem 0 0.625rem 0",
                  justifyContent: "space-between",
                }}
              >
                <Grid xs={12} md={3} item>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="messageInterval-label">
                      Intervalo Randômico de Disparo
                    </InputLabel>
                    <Select
                      name="messageInterval"
                      id="messageInterval"
                      labelId="messageInterval-label"
                      label="Intervalo Randômico de Disparo"
                      value={settings.messageInterval}
                      classes={formInput}
                      onChange={(e) => handleOnChangeSettings(e)}
                    >
                      <MenuItem className={classes.dropdownBtn} value={0}>
                        Sem Intervalo
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={5}>
                        5 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={10}>
                        10 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={15}>
                        15 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={20}>
                        20 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={30}>
                        30 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={40}>
                        40 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={50}>
                        50 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={60}>
                        60 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={120}>
                        120 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={180}>
                        180 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={300}>
                        300 segundos
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={3} item>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="longerIntervalAfter-label">
                      Intervalo Maior Após
                    </InputLabel>
                    <Select
                      name="longerIntervalAfter"
                      id="longerIntervalAfter"
                      labelId="longerIntervalAfter-label"
                      label="Intervalo Maior Após"
                      value={settings.longerIntervalAfter}
                      classes={formInput}
                      onChange={(e) => handleOnChangeSettings(e)}
                    >
                      <MenuItem className={classes.dropdownBtn} value={0}>
                        Não definido
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={5}>
                        5 mensagens
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={10}>
                        10 mensagens
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={15}>
                        15 mensagens
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={20}>
                        20 mensagens
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={25}>
                        25 mensagens
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={30}>
                        30 mensagens
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={12} md={3} item>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                  >
                    <InputLabel id="greaterInterval-label">
                      Intervalo de Disparo Maior
                    </InputLabel>
                    <Select
                      name="greaterInterval"
                      id="greaterInterval"
                      labelId="greaterInterval-label"
                      label="Intervalo de Disparo Maior"
                      value={settings.greaterInterval}
                      classes={formInput}
                      onChange={(e) => handleOnChangeSettings(e)}
                    >
                      <MenuItem className={classes.dropdownBtn} value={0}>
                        Sem Intervalo
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={20}>
                        20 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={30}>
                        30 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={40}>
                        40 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={50}>
                        50 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={60}>
                        60 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={80}>
                        80 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={100}>
                        100 segundos
                      </MenuItem>
                      <MenuItem className={classes.dropdownBtn} value={120}>
                        120 segundos
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </div>
              <div style={{ display: "flex" }}>
                <Grid xs={12} className={classes.textRight} item>
                  <Button
                    onClick={() => setShowVariablesForm(!showVariablesForm)}
                    color="primary"
                    className={classes.btnLink}
                    style={{ marginRight: 10 }}
                  >
                    Adicionar Variável
                  </Button>
                  <Button
                    onClick={saveSettings}
                    color="primary"
                    variant="contained"
                    className={classes.btn}
                  >
                    Salvar Configurações
                  </Button>
                </Grid>
              </div>
            </Paper>
          </Card>
          {showVariablesForm && (
            <Paper
              className={classes.mainPaper}
              style={{
                boxShadow: "none",
                padding: "0.625rem",
                border: "none",
                marginTop: "0.625rem",
              }}
              variant="outlined"
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  gap: "0.625rem",
                  margin: "0.625rem 0 0.625rem 0",
                  padding: "0 1.25rem 1.25rem 0.625rem",
                  justifyContent: "space-between",
                }}
              >
                <Grid xs={12} md={6} item>
                  <TextField
                    label="Atalho"
                    variant="outlined"
                    value={variable.key}
                    name="key"
                    classes={formInput}
                    onChange={handleOnChangeVariable}
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} md={6} item>
                  <TextField
                    label="Conteúdo"
                    variant="outlined"
                    value={variable.value}
                    name="value"
                    classes={formInput}
                    onChange={handleOnChangeVariable}
                    fullWidth
                  />
                </Grid>
              </div>
              <Grid
                xs={12}
                className={classes.textRight}
                style={{ padding: "0 1.25rem" }}
                item
              >
                <Button
                  onClick={() => setShowVariablesForm(!showVariablesForm)}
                  color="primary"
                  className={classes.btnLink}
                  style={{ marginRight: 10 }}
                >
                  Fechar
                </Button>
                <Button
                  onClick={addVariable}
                  color="primary"
                  className={classes.btn}
                  variant="contained"
                >
                  Adicionar
                </Button>
              </Grid>
            </Paper>
          )}
        </Grid>
        {settings.variables.length > 0 && (
          <Paper
            className={classes.mainPaper}
            style={{
              boxShadow: "none",
              padding: "0.625rem",
              border: "none",
              marginTop: "0.625rem",
            }}
            variant="outlined"
          >
            <Grid
              xs={12}
              className={classes.textRight}
              style={{ padding: "0 1.25rem" }}
              item
            >
              <Table size="small" style={{ border: "0.063rem solid #e0e0e0", borderRadius: "0.313rem" }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Atalho</TableCell>
                    <TableCell>Conteúdo</TableCell>
                    <TableCell style={{ width: "1%" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(settings.variables) &&
                    settings.variables.map((v, k) => (
                      <TableRow key={k}>
                        <TableCell>{"{" + v.key + "}"}</TableCell>
                        <TableCell>{v.value}</TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setSelectedKey(v.key);
                              setConfirmationOpen(true);
                            }}
                          >
                            <i
                              style={{ color: "gray" }}
                              className={"fi-rr-trash"}
                            ></i>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Grid>
          </Paper>
        )}
      </Box>
    </MainContainer>
  );
};

export default CampaignsConfig;
