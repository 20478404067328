import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    flex: 1,
    padding: theme.spacing(2),
    //height: 'calc(100% - 48px)',
    // height: "100%",
  },

  contentWrapper: {
    height: "100%",
    overflowY: "hidden",
    display: "flex",
    flexDirection: "column",
  },
}));

const MainContainer = ({ children, alignItems = "stretch" }) => {
  const { mainContainer, contentWrapper } = useStyles();

  return (
    <Container className={mainContainer}>
      <div style={{ alignItems }} className={contentWrapper}>
        {children}
      </div>
    </Container>
  );
};

export default MainContainer;
