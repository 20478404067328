import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    width: "100%",
    padding: "0 0.625rem 1.25rem 0.625rem",
  },
  titleTypograph: {
    color: "#9747FF",
    fontSize: "0.813rem",
  },
  separator: {
    display: "flex",
    position: "absolute",
    width: "100%",
    height: "0.125rem",
    backgroundColor: "#9747FF",
    top: "3.9rem",
    left: 0,
  },
  container: {
    position: "relative",
    padding: "1.25rem 1.875rem",
    maxWidth: "41.25rem",
    width: "100%",
    borderRadius: "0.625rem",
    border: "0.063rem solid #e0e0e0",
    boxShadow: "none",
  },
}));

export function Card({ children, title, fullWidth }) {
  const { container, titleContainer, titleTypograph, separator } = useStyles();
  return (
    <Paper className={container} style={fullWidth && { maxWidth: "none" }}>
      <span className={separator}></span>
      <Paper style={{ boxShadow: "none" }}>
        <div className={titleContainer}>
          <Typography className={titleTypograph} variant="h5" gutterBottom>
            {title}
          </Typography>
        </div>
        {children}
      </Paper>
    </Paper>
  );
}
